.sidebar .nav-link {
  color: #F1F1F1;
  background: #233247;
}
.sidebar .nav-link.active{
  color: #F1F1F1;
  background: #0095A0;
}

.sidebar .nav-link:hover {
  color: #F1F1F1;
  background: #00CB65;
}

.sidebar-minimized .sidebar .nav-link:hover {
  width: 50px;
  background: #20a8d8;
  overflow:hidden;
}


.sidebar .nav-link.active .nav-icon{
  color: white;
}

.sidebar .nav {
  background: #233247;
}

.sidebar .sidebar-header {
 background: #233247;
}

.sidebar .sidebar-minimizer {
  background: #233247;
}
.sidebar .sidebar-minimizer:hover {
  background: #233247;
}

.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background: #0095A0;
  color: #F1F1F1;
}

.brand-minimized .app-header .navbar-brand {
  width: 155px;
}

.bulkhelper {
  text-align: center;
  background: #e3e8ee;
  /* color: #FFFFFF; */
  font-size: 17px;
  border-radius: 4px;
  /* border: 1px solid grey; */
  box-shadow: 2px 3px 13px -4px rgba(0,0,0,0.17);
  width: 400px;
  padding: 4px;
}

.card-footer {
  background: #FFFFFF;
  /* border: #FFFFFF; */
  text-align: center;
  padding: 0 0 16px 0;
}

.card-body {
  padding-top: 30px;
}



button.btn.btn-primary.btn-sm {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #00CB65;
  color: black;
  border-radius: 23px;
  font-size: 24px;
}

button.btn.btn-secondary.btn-sm {
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #D1D1D1;
  color: black;
  border-radius: 23px;
  font-size: 24px;
}

input.form-control {
  /* outline: 0; */
  border-width: 1px;
  border-color: gainsboro;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 300px;

}

input.form-control::placeholder {
  /* color: black; */
}

.help-block.form-text.text-muted {
  padding-left: 13px
}

form {
  padding: 0 30px 0px 30px;
  text-align: center;
}

.upload.file{
  border: grey;
  border-width: 2px;
  border-color: grey;
  border-style: dashed;
  width: 100px;
  text-align: center;
  padding: 0 0 0 0;
  font-size: 38px;
  background-color: #F1F1F1;
  color: #00CB65;
  height:auto; 
  margin:0 auto;
  margin-bottom: 20px;
}

.vl {
  border-left: 3px solid #DBDBDB;
  height: 510px;
  margin-top: 25px;
}

.scanFields{
  display: flex;
  justify-content: center;
}

.template-link {
  position: absolute;
  bottom: 27px;
  left: 23%;
}

label {
  padding-top: 7px;
  text-align: left !important;
}

.modal-dialog {
  max-width: 700px;
}

.file-title {
  margin: 0 0 22px 0;
  display: flex;
}

.nav-tabs {
  border: 0px;
}

.tab-content {
  border: 0px;
  padding-top: 13px;
}

.nav-tabs .nav-link {
  /* border: 1px solid #ccc; */
  /* border-bottom: 0px; */
  background: #c1c9d2;
  border: 1px solid #8792a2;
  border-top: 2px solid #8792a2;
  font-size: 17px;
  border-bottom: 0px ;
}

.nav-tabs .nav-link:hover {
  /* border: 1px solid #ccc; */
  /* border-bottom: 0px; */
  background: #c1c9d2;
  border: 1px solid #8792a2;
  border-top: 2px solid #8792a2;
  font-size: 17px;
  border-bottom: 0px ;

}
.nav-tabs .nav-link.active{
  background: #fff;
  border: 0px;
  border-top: 5px outset rgba(1,149,160,0.62);
  font-weight: bold;
  font-size: 17px;
  box-shadow: 10px 3px 13px -20px rgba(0,0,0,0.17);
}
ul.nav.nav-tabs {
  display: table;
  width: 100%;
  table-layout: fixed;
}
ul.nav.nav-tabs > li {
  float: none;
  display: table-cell;
}
ul.nav.nav-tabs > li > a {
  text-align: center;
}

label {
  float: right;
}

.flag-select {
  float: left;
  padding-top: 4px;
}
.flag-select img{
  top: 0em !important;
}

.option-label {
  color: #50566b;
  font-weight: 500;
}