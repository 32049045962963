// Styles
// CoreUI Icons Set
// @import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

// .sidebar-minimizer {
//   position: initial;
//   background-color: white;
// }

.nav-link {
  background-color: white;
}